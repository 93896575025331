import React, { Component } from "react";
import "../style/home.scss";
import headImg from "../image/header.png";
import EarthIcon from "../icon/EarthIcon";
import * as GetStoryListAPI from "../apis/GetStoryListAPI";
import { STORY_URL } from "../constants/ApiEndpointConstants";
import faceBook from "../image/facebook.svg";
import twitter from "../image/twitter.svg";
import email from "../image/email.svg";
import LoveIcon from "../icon/LoveIcon";
import * as GetCommentListAPI from "../apis/GetCommentListAPI";
import moment from "moment";
import Loading from "./common/Loading";
import CloseIcon from "../icon/CloseIcon";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import i5M60 from "../image/bmw-i5-m60.png";
import ixM60 from "../image/bmw-ix-m60.png";
import i4 from "../image/bmw-i4.png";
import bmw3 from "../image/bmw3.png";
import webBanner from "../image/web-header.png";
import riBen from "../image/riben.png";
import logo_jrx from "../image/jr_x.png";
import logo_jrd from "../image/jreast_wht.png";
import jrd from "../image/jr_d.png";
import logo from "../image/logo.svg";
import pageTop from "../image/pagetop.png";
import suica_logo from "../image/suica_logo.png";

import * as FacebookAPI from "../apis/FacebookAPI";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      story: {},
      commentList: [],
      storyTime: "",
      driveNum: "",
      swiperList: [i5M60, ixM60, bmw3, i4],
      cartMask: false,
    };
    this.changeLoveState = this.changeLoveState.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.scroll = this.scroll.bind(this);
    this.changeCartMask = this.changeCartMask.bind(this);
    this.routeBMW = this.routeBMW.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // 试驾数
    let driveNum = Math.floor(Math.random() * (50 - 200 + 1)) + 200;
    // 获取当前时间戳
    let now = new Date().getTime();
    // 获取前后n天时间
    let endTime = new Date(now - 10 * 24 * 60 * 60 * 1000).getTime();
    let startTime = new Date(now - 13 * 24 * 60 * 60 * 1000).getTime();
    let oneDayTime = new Date(now - 1 * 24 * 60 * 60 * 1000).getTime();
    // 随机帖子时间
    let renderTime =
      Math.floor(Math.random() * (endTime - startTime + 1)) + startTime;
    // 格式化
    let storyTime = moment(renderTime).format("MMMM D . h:mm A");
    this.setState({ storyTime, driveNum });
    let time = [renderTime, now];
    this.toggleShow();
    GetStoryListAPI.getStoryList(1, 4).then((res) => {
      this.toggleShow();
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        let id = data.list[0].id;
        this.setState({ story: data.list[0] }, () => {
          GetCommentListAPI.getCommentListAPI(1, 20, id).then((res) => {
            var code = res.data.code;
            if (code === 1) {
              var data = res.data.data.list;
              data.map((item, index) => {
                item.loveState = false;
                // 点赞数
                item.num = Math.floor(Math.random() * (100 - 1000 + 1)) + 1000;
                let render =
                  Math.floor(Math.random() * (time[1] - time[0] + 1)) + time[0];
                if (index < 3) {
                  render =
                    Math.floor(Math.random() * (time[1] - oneDayTime + 1)) +
                    oneDayTime;
                  if (render < time[1]) {
                    time[1] = render;
                    item.time = moment(render).fromNow();
                  }
                } else {
                  if (render < time[1]) {
                    time[1] = render;
                    item.time = moment(render).format("MMMM D . h:mm A");
                  }
                }
              });
              this.setState({ commentList: data });
            }
          });
        });
      }
    });
    window.addEventListener("scroll", this.scroll, true);
  }

  changeLoveState(index) {
    let { commentList } = this.state;
    commentList.map((item, itemIndex) => {
      if (index === itemIndex) {
        item.loveState = !item.loveState;
        if (item.loveState === true) {
          item.num++;
          return;
        } else {
          return item.num--;
        }
      }
    });
    this.setState({ commentList });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroll, true);
  }

  scroll() {
    setTimeout(() => {
      this.setState({ cartMask: true });
    }, 3000);
  }

  changeCartMask() {
    setTimeout(this.setState({ cartMask: !this.state.cartMask }), 3000);
  }

  routeBMW() {
    let { location, history } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Model",
        search: location.search,
      });
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const { story, commentList, storyTime, driveNum, swiperList, cartMask } =
      this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        {/* <div className={`cart-model ${cartMask ? "cart-show" : ""}`}>
          <CloseIcon
            size={17}
            className="close"
            color="#27458f"
            onClick={this.changeCartMask}
          />
          <div className="row" onClick={this.routeBMW}>
            <div className="left">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={100}
                slidesPerView={1}
                autoplay={{ delay: 1000 }}
                loop={true}
                // navigation
                // pagination={{ clickable: true }}
              >
                {swiperList.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <img src={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="info-detail">
              <div className="title">1年間無料試乗</div>
              <div className="txt">本日の残り {driveNum} 台の試乗</div>
              <div className="tip">Apply Now</div>
            </div>
          </div>
        </div> */}
        <div className="home">
          <div className="home-page">
            <div className="head">
              <div className="img">
                <img src={jrd} alt="" />
                <div className="animate-button" onClick={this.routeBMW}>
                  Apply Now
                </div>
                {/* <img src={suica_logo} alt="" className="pc-img" />
                <img src={suica_logo} alt="" className="web-img" /> */}
              </div>
            </div>
            <div className="content">
              {/* <div className="profile-box">
                <div className="profile-img">
                  <img src={STORY_URL + story.logo} alt="" />
                </div>
                <div className="info-box">
                  <div className="name">{story.nickname}</div>
                  <div className="time">
                    {" "}
                    <EarthIcon size={20} color="#737B8B" />{" "}
                    <span>{storyTime}</span>
                  </div>
                </div>
              </div> */}
              <div className="story-content">
                <p dangerouslySetInnerHTML={{ __html: story.description }}></p>
              </div>
              {/* <div className="foot">
                <div className="profile">
                  <div className="img">
                    <img src={STORY_URL + story.logo} alt="" />
                  </div>
                  <div className="info">
                    By <span>{story.nickname}</span>
                  </div>
                </div>
                <div className="right">
                  <li>
                    <img src={faceBook} alt="" />
                  </li>
                  <li>
                    <img src={twitter} alt="" />
                  </li>
                  <li>
                    <img src={email} alt="" />
                  </li>
                </div>
              </div> */}
              <div className="comment-content">
                <div className="comment-head">
                  <div className="zan">
                    <LoveIcon size={20} />
                    <span>16K</span>
                  </div>
                  <div className="right">
                    <span>452 comments</span>
                    <span>812 shares</span>
                  </div>
                </div>
                <div className="comment-ul">
                  {commentList &&
                    commentList.map((item, index) => {
                      return (
                        <div className="comment-li" key={index}>
                          <div className="profile-img">
                            <img src={STORY_URL + item.logo} alt="" />
                          </div>
                          <div className="li-txt">
                            <div className="name">{item.nickname}</div>
                            <div
                              className="content-txt"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></div>
                            <div className="time-box">
                              <EarthIcon size={20} />
                              <p className="time-p">{item.time}</p>
                              <div className="num">{item.num}</div>
                              <div
                                className={`love ${
                                  item.loveState ? "active" : ""
                                }`}
                                onClick={() => this.changeLoveState(index)}
                              ></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="footer">
                <div className="pc-btn" onClick={this.scrollToTop}>
                  ページの先頭へ
                </div>
                <div className="footer-logo">
                  <img src={logo_jrd} alt="" />
                </div>
                <div className="footer-text">
                  Copyright © East Japan Railway Company All Rights Reserved.
                  <br />
                  {/* このサイトに掲載されている情報はJR东日本が提供しております。 */}
                </div>
              </div>
            </div>
            <div className="web-btn">
              <img src={pageTop} alt="" onClick={this.scrollToTop} />
            </div>
          </div>
        </div>
        <div className="btn-model">
          <div className="apply-btn" onClick={this.routeBMW}>
            今すぐ応募、5,000名限定!
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
